import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import apiClient from "../../../auth/apiClient";
import { BuildTree } from "./CoreEntityUtil";

import * as c from "../../../constants/";


// Thunks
export const LoadEntities = createAsyncThunk(
    "coreEntity/add",
    async ({ entityID }) => {
        const response = await apiClient.get(`entities/${entityID}/tree`);

        // Assuming response.data is a flat list of entities you can directly return.
        return response.data;
    }
);

const entityAdapter = createEntityAdapter({
    selectId: (entity) => entity.entityid, // Assuming 'entityid' is the unique identifier
});

// Slice
const slice = createSlice({
    name: "coreEntity",
    initialState: entityAdapter.getInitialState({
        ContextID: null, //adding ContextID to the initial state
    }), // Initialize with adapter state
    reducers: {
        setEntities:(state, action) => {
            const { entityID, entities } = action.payload;

            state.ContextID = entityID;

            const updatedEntities = entities.map((entity) => ({
                ...entity,
                context: true, // Mark all loaded entities as part of the context
            }));

            entityAdapter.setAll(state, updatedEntities);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoadEntities.fulfilled, (state, action) => {

                const { entityID } = action.meta.arg;

                state.ContextID = entityID;

                Object.values(state.entities).forEach((entity) => {
                    entity.context = false;
                });

                const updatedEntities = action.payload.map((entity) => ({
                    ...entity,
                    context: true, // Mark new entities as part of the context
                }));

                entityAdapter.upsertMany(state.entities, updatedEntities); // Add or update entities from the built data
            })
    },
});

// Selectors

const entitySelectors = entityAdapter.getSelectors((state) => state.coreEntity);

export const {
    selectAll: selectAllEntities, //Retreive all entities as an array
    selectById: selectEntityById, // Retrieve a single entity by its ID
    selectIds: selectEntityIds, // Retrieves an array of EntityIDs,
    selectTotal: selectTotalEntities, // Retrieves the total number of EntityIDs
} = entitySelectors;


//Replaces the context selector
export const selectEntitiesInContext = createSelector(
    selectAllEntities,
    (entities) => entities.filter(({ context }) => context)
)

//Functions
export const loadEntityList = (entityID) => async (dispatch) => {
    apiClient
        .get(`entities/${entityID}/tree`)
        .then((resp) => {
            dispatch(setEntities({ entityID, entities: resp.data })); //load the full entity tree into EntityList
        })
        .catch((err) => {
            console.error(
                "Encountered error while loading all entities from context:",
                err
            );
        })
        .finally(() => {
            //do nothing, getting IDs next
        });


};


export const { setEntities} = slice.actions;

export default slice.reducer;
