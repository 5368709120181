// src/app/store.js

import { configureStore } from "@reduxjs/toolkit";
import entitiesReducer from "./slices/entities.js";
import userReducer from "./slices/user.js";
import callcenterReducer from "./slices/callcenter.js";
import activityReducer from "./slices/filters/activity.js";
import payOnEntryReducer from "./slices/POE/payOnEntry";
import shiftSessionReducer from "./slices/shiftSession/shiftSession.js";
import cashierReducer from "./slices/cashier/cashier";
import peripheralsReducer from "./slices/peripherals.js";
import countriesReducer from './slices/countries.js';
import featureFlagsReducer from "./slices/featureflags/featureflags.js";
import entityScopeReducer from "./slices/entityScope.js";
import coreEntitiesReducer from "./slices/CoreEntity/CoreEntity.js";

const store = configureStore({
  reducer: {
    entities: entitiesReducer,
    user: userReducer,
    callcenter: callcenterReducer,
    activity: activityReducer,
    payOnEntry: payOnEntryReducer,
    shiftSession: shiftSessionReducer,
    cashier: cashierReducer,
    peripherals: peripheralsReducer,
    countries: countriesReducer,
    featureFlags: featureFlagsReducer,
    entityScope: entityScopeReducer,
    coreEntities: coreEntitiesReducer
  },
});

export default store;
export const AppDispatch = store.dispatch;
